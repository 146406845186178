/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import StickySocials from "../StickySocials";
import Header from "../Header";
import Footer from "../Footer";

import "./layout.scss";

import { useMediaQuery } from "react-responsive";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 40.625rem)",
  });

  return (
    <>
      <Header
        description={data.site.siteMetadata.description}
        siteTitle={data.site.siteMetadata.title}
      />
      <div
        style={
          {
            // margin: `0 auto`,
            // maxWidth: 960,
            // display: `flex`,
            // padding: `0 1.0875rem 1.45rem`,
            // marginRight: `1.0875rem`,
          }
        }
      >
        <main style={{ margin: `0 1.0875rem` }}>{children}</main>
        {/* Make this accessible */}
        {!isMobile && <StickySocials />}
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
