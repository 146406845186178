import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  fab,
  faInstagram,
  faVimeoSquare,
} from "@fortawesome/free-brands-svg-icons";

import "./sticky-socials.scss";

library.add(fab);

const StickySocials = () => {
  return (
    <div className="sticky-socials--container">
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="https://www.instagram.com/luisajcharles/"
      >
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="mailto:ljcharles@hotmail.co.uk"
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="https://vimeo.com/user27293186"
      >
        <FontAwesomeIcon icon={faVimeoSquare} />
      </a>
    </div>
  );
};

export default StickySocials;
