import React from "react";
import PropTypes from "prop-types";
// import { slide as BurgerMenu } from "react-burger-menu";
import { Link } from "gatsby";
import StickySocials from "../StickySocials";

import "./header.scss";

import { useMediaQuery } from "react-responsive";

const Header = ({ siteTitle, description }) => {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 40.62rem)",
  });

  // const isTabletOrDesktop = useMediaQuery({
  //   query: "(min-device-width: 40.625rem)",
  // });
  return (
    <header className="header">
      <div className="header__logo--container">
        <h1>
          <Link to="/">{siteTitle}</Link>
        </h1>
        {isMobile ? (
          <>
            <p>Interaction Designer | Multimedia Artist</p>
            <p>Filmmaker</p>
          </>
        ) : (
          <span>{description}</span>
        )}
      </div>
      <nav>
        {isMobile && (
          <>
            <StickySocials />
            {/* <BurgerMenu right>
              <Link to="/" className="menu-item" activeClassName="active">
                Home
              </Link>
              <Link
                to="/projects"
                className="menu-item"
                activeClassName="active"
              >
                Portfolio
              </Link>
              <a href="/">Home</a>
            </BurgerMenu> */}
          </>
        )}
        {/* ) : (<> */}
        {/* <Link to="/" activeClassName="active">
                Home
              </Link>
              <Link to="/projects" activeClassName="active">
                Portfolio
              </Link> */}
        {/* <Link to="/" activeClassName="active">
                CV
              </Link> */}
        {/* <Link to="/" activeClassName="active">
                Contact
              </Link> */}
        {/* </>
          )} */}
      </nav>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  description: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
  description: ``,
};

export default Header;
